import React from "react";
import { graphql } from "gatsby";
import Layout from "./../shared/components/layout";
import PageHeader from "../shared/components/page-header";
import ScreenshotsSlider from "./components/screenshots-slider";

const ClubDetail = ({ data, pageContext }) => {
  const { frontmatter, html } = data.clubData.childMarkdownRemark

  const metas = {
    title: frontmatter.metaTitle,
    description: frontmatter.metaDescription
  }

  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
      noindex={true}
    >
      <PageHeader
        title={frontmatter.title}
        description={frontmatter.description}
        type="club-detail"
        icon={frontmatter.icon}
        googlePlayLink={frontmatter.googlePlayLink}
        appStoreLink={frontmatter.appStoreLink}
      >
        {html}
      </PageHeader>

      <ScreenshotsSlider images={frontmatter.screenshots} />
    </Layout>
  );
};

export default ClubDetail;


export const query = graphql`
  query ($language: String!, $dataPath: String!) {
    clubData: file(relativePath: {regex: $dataPath}) {
      childMarkdownRemark {
        html
        frontmatter {
            metaTitle
            metaDescription
            title
            description
            icon
            googlePlayLink
            appStoreLink
            screenshots
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
