import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import DynamicImage from "../../../shared/components/dynamic-image"
import { useInView } from "react-intersection-observer";
import 'swiper/css';
import './index.scss'

const ScreenshotsSlider = ({ images }) => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    return (
        <div ref={ref} className={`screenshots-slider wrapper-fluid grid${inView ? ' in-view' : ''}`}>
            <Swiper
                slidesPerView="auto"
                spaceBetween={10}
            >
                {
                    images.map((img, index) => {
                        return (
                            <SwiperSlide key={`${img}-${index}`}>
                                <DynamicImage imageKey={img} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )

}

export default ScreenshotsSlider