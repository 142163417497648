import React from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import DynamicImage from '../dynamic-image'
import './index.scss'

const PageHeader = ({ title, description, type = 'default', icon = null, googlePlayLink = "", appStoreLink = "", children }) => {
    const { t } = useTranslation()

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    return (
        <div ref={ref} className={`page-header page-header--${type}${inView ? ' in-view' : ''}`}>
            <div className="wrapper-fluid">
                <div className="main-info grid">
                    <div className="main-info">
                        {type === 'club-detail' && icon &&
                            <div className="club-icon">
                                <DynamicImage imageKey={icon} />
                            </div>
                        }
                        <h1 className='title'>{title}</h1>
                        {type === 'club-detail' && (googlePlayLink !== '' || appStoreLink !== '') &&
                            <div className="club-apps-wrapper">
                                <span className="title">{t("clubs.downloadApp")}</span>
                                <div className="club-apps-links">
                                    {googlePlayLink !== '' &&
                                        <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" aria-label={t("clubs.downladAppGooglePlay")}>
                                            <DynamicImage imageKey={"google-play"} />
                                        </a>
                                    }
                                    {appStoreLink !== '' &&
                                        <a href={appStoreLink} target="_blank" rel="noopener noreferrer" aria-label={t("clubs.downloadAppStore")}>
                                            <DynamicImage imageKey={"app-store"} />
                                        </a>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="description-wrapper">
                        <p className="description">{description}</p>
                        {type === 'club-detail' &&
                            <div className="static-page-content" dangerouslySetInnerHTML={{ __html: children }}></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeader